<template>
    <div class="frm">
        <div class="search-bar">
            <div class="flex flex-align-center" style="position: relative;">
                <div class="title">风格：</div>
                <div v-if="styles" class="flex-1 styles" :style="{height:showAllStyles ? 'auto':'40px'}">
                    <div v-for="(style,index) in styles" :key="index" @click="query.style=style.id" :class="{ selected: query.style==style.id }">{{style.name}}</div>
                </div>
                <div class="more" @click="showAllStyles=!showAllStyles">{{ showAllStyles ? '收起':'更多'}}</div>
            </div>
            <div class="flex flex-align-center">
                <div class="title">价格：</div>
                <div>
                    <el-input v-model="query.price.start" style="width: 100px;"></el-input>
                    &nbsp;
                    <span>至</span>
                    &nbsp;
                    <el-input v-model="query.price.end" style="width: 100px;"></el-input>
                    &nbsp;
                    <el-button type="info" plain>确定</el-button>
                </div>
            </div>
            <div class="flex flex-align-center">
                <div class="title">货源：</div>
                <el-checkbox v-model="query.srcType">仅看现货款</el-checkbox>
            </div>
        </div>
        <div class="items">
            <div v-for="(item,index) in items" :key="index">
                <div class="flex item">
                    <img :src="item.img" style="width: 230px;height: 230px;">
                    <div class="detail">
                        <div class="title">{{item.name}}</div>
                        <template v-if="item.designer">
                            <img :src="item.designer.head" style="width: 60px;height: 60px;">
                            <div class="title">{{item.designer.name}}</div>
                        </template>
                        <div class="flex flex-justify-between">
                            <div>历史订单：</div>
                            <div><b style="font-size: 18px;">{{item.orderCount}}</b>单</div>
                        </div>
                        <div class="flex flex-justify-between">
                            <div>浏览次数：</div>
                            <div><b style="font-size: 18px;">{{item.pv}}</b>次</div>
                        </div>
                    </div>
                    <div class="flex-1 flex factorys">
                        <div v-for="(factory,_index) in item.factorys.slice(0,2)" :key="_index" class="flex-1">
                            <div class="title" style="text-align: center;">{{factory.name}}</div>
                            <div class="table" style="text-align: center;margin-top: 20px;">
                                <div class="flex" style="font-weight: bold;">
                                    <div class="flex-1">交期</div>
                                    <div class="flex-1">数量</div>
                                    <div class="flex-1">单价</div>
                                </div>
                                <div v-for="(row,_index) in factory.list" :key="_index" class="flex">
                                    <div class="flex-1">{{row.period}}</div>
                                    <div class="flex-1">{{row.count}}</div>
                                    <div class="flex-1">{{row.price}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-align-center">
                        <span class="icon iconfont icon-youjiantou"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { getStyles } from '@/service/item';
    export default {
        components: {
        },
        data() {
            return {
                styles:null,
                query:{
                    style:null,
                    price:{
                        start:null,
                        end:null
                    },
                    srcType:null
                },
                showAllStyles:false,
                items: [
                    {
                        img: require('@/assets/images/sale/mall/位图.png'),
                        designer:{
                            head: require('@/assets/images/sale/mall/Bitmap.png'),
                            name:'张哲希'
                        },
                        name:'组合沙发',
                        orderCount:100,
                        pv:100,
                        factorys: [
                            { name: '工厂1',list:[
                                {period:1,count:100,price:100},
                                {period:2,count:100,price:100},
                                {period:3,count:100,price:100},
                                {period:4,count:100,price:100}
                            ] }, 
                            { name: '工厂2',list:[
                                {period:1,count:100,price:100},
                                {period:2,count:100,price:100},
                                {period:3,count:100,price:100},
                                {period:4,count:100,price:100}
                            ] }
                        ]
                    },
                    {
                        img: require('@/assets/images/sale/mall/位图.png'),
                        designer:{
                            head: require('@/assets/images/sale/mall/Bitmap.png'),
                            name:'张哲希'
                        },
                        name:'组合沙发',
                        orderCount:100,
                        pv:100,
                        factorys: [
                            { name: '工厂1',list:[
                                {period:1,count:100,price:100},
                                {period:2,count:100,price:100},
                                {period:3,count:100,price:100},
                                {period:4,count:100,price:100}
                            ] }, 
                            { name: '工厂2',list:[
                                {period:1,count:100,price:100},
                                {period:2,count:100,price:100},
                                {period:3,count:100,price:100},
                                {period:4,count:100,price:100}
                            ] }
                        ]
                    },
                    {
                        img: require('@/assets/images/sale/mall/位图.png'),
                        designer:{
                            head: require('@/assets/images/sale/mall/Bitmap.png'),
                            name:'张哲希'
                        },
                        name:'组合沙发',
                        orderCount:100,
                        pv:100,
                        factorys: [
                            { name: '工厂1',list:[
                                {period:1,count:100,price:100},
                                {period:2,count:100,price:100},
                                {period:3,count:100,price:100},
                                {period:4,count:100,price:100}
                            ] }, 
                            { name: '工厂2',list:[
                                {period:1,count:100,price:100},
                                {period:2,count:100,price:100},
                                {period:3,count:100,price:100},
                                {period:4,count:100,price:100}
                            ] }
                        ]
                    },
                    {
                        img: require('@/assets/images/sale/mall/位图.png'),
                        designer:{
                            head: require('@/assets/images/sale/mall/Bitmap.png'),
                            name:'张哲希'
                        },
                        name:'组合沙发',
                        orderCount:100,
                        pv:100,
                        factorys: [
                            { name: '工厂1',list:[
                                {period:1,count:100,price:100},
                                {period:2,count:100,price:100},
                                {period:3,count:100,price:100},
                                {period:4,count:100,price:100}
                            ] }, 
                            { name: '工厂2',list:[
                                {period:1,count:100,price:100},
                                {period:2,count:100,price:100},
                                {period:3,count:100,price:100},
                                {period:4,count:100,price:100}
                            ] }
                        ]
                    },
                ]
            }
        },
        mounted() {

        },
        created() {
            getStyles().then(rst => {
                this.styles = rst;
                console.log(this.styles);
            });
        },
        methods: {

        }
    }
</script>
<style scoped>
    .frm{
        width: 1200px;
        overflow: hidden;
        margin: 0 auto;
        padding: 30px;
        background: #fff;
    }
    .search-bar{
        border:1px solid rgba(204,204,204,1);
    }
    .search-bar>*{
        padding: 20px;
    }
    .search-bar>*:not(:last-child){
        border-bottom: 1px dashed rgba(204,204,204,1);
    }
    .search-bar .title{
        width: 50px;
        font-weight:bold;
        color:rgba(77,77,77,1);
    }
    .styles{
        overflow: hidden;
    }
    .styles>*{
        margin: 5px;
        padding: 5px 10px;
        border-radius: 5px;
        float: left;
        cursor: pointer;
        color: #808080;
    }
    .styles>*.selected{
        background: darkblue;
        color: #fff;
    }
    .more{
        position: absolute;
        right: 0;
        cursor: pointer;
        bottom: 0;
        padding: 10px;
        color: #4D4D4D;
    }
    .item{
        border:1px solid rgba(204,204,204,1);
        padding: 20px;
        margin: 10px auto;
    }
    
    .detail{
        padding: 0 20px;
        border-right: 1px solid #eee;
        width: 300px;
    }
    .detail>*:not(:first-child){
        margin-top: 20px;
    }
    .title{
        font-size:16px;font-weight:bold;color:rgba(41,41,41,1);
    }
    .factorys{
        overflow: hidden;
    }
    .factorys>*{
        margin: 0 10px;
    }
    .table{
        background: #eee;
    }
    .table>div>div{
        padding: 10px;
    }
</style>